div{
    font-family: "Quicksand", sans-serif;
}
.wrapper{
  display: flex;
  align-items: flex-start;
}
.form-wrapper{
    display: flex;
    font-size: 21px;
    align-items: flex-end;
    flex-direction: column;
    width: 35%;
    font-weight: 700;
    margin-left: 400px;
    margin-right: 30px;
}
.form-input{
    border-radius: 10px;
    height: 30px;
    border: 1px solid #f3f4f7;
    font-size: 21px;
    margin-left: 20px;
    box-shadow: 2px 2px 3px rgba(55, 84, 170, .15),
      inset 0px 0px 4px rgba(255, 255, 255, 0),
      inset 7px 7px 15px rgba(55, 84, 170, .15),
      inset -7px -7px 20px rgba(255, 255, 255, 1),
      0px 0px 4px rgba(255, 255, 255, .2) !important;
}
.input-wrapper{
    margin-bottom: 20px;
}

.submit-btn{
    font-family: inherit;
    font-weight: 600;
    height: 40px;
    font-size: 15px;
    width: 120px;
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 10px;
    color: #6D7587;
    background-color: #f2f3f7;
    box-shadow: 8px 8px 12px -2px rgba(172, 173, 175, 0.4), -6px -6px 12px -1px white;
    transition: all 250ms ease-in-out;
    cursor:pointer;
  }
  .submit-btn:active {
    box-shadow: inset -4px -4px 6px -1px white, inset 2px 2px 8px -1px rgba(72, 79, 96, 0.5);
    border-color: rgba(255, 255, 255, 0.14);
  }

.policy-list{
  display: flex;
  flex-direction: column;
  width: 400px;
  align-items: flex-start;
  margin: auto;
}
.policy-wrapper{
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: flex-start;
  justify-content: left ;
}