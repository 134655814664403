@import url("https://fonts.googleapis.com/css?family=Quicksand:400,500,700&subset=latin-ext");

body {
  font-family: "Quicksand", sans-serif;
  color: #324e63;
}
.profile-card__cnt {
  margin-top: -35px;
  text-align: center;
  padding: 0 20px;
  padding-bottom: 40px;
  transition: all 0.3s;
}
.profile-card__name {
  font-weight: 700;
  font-size: 24px;
  color: #6944ff;
  margin-bottom: 15px;
  margin-top: 10px;
}
.profile-card__txt {
  font-size: 16px;
  font-weight: 500;
  color: #324e63;
}
.profile-card__txt strong {
  font-weight: 700;
}
.profile-card-inf {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 35px;
}
.profile-card-inf__item {
  padding: 10px 35px;
  min-width: 150px;
}

.profile-card-inf__title {
  font-weight: 700;
  font-size: 27px;
  color: #324e63;
}
.profile-card-inf__txt {
  font-weight: 500;
  margin-top: 7px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 60%;
    overflow-y: scroll;
    border:none;
    width:75%;
    margin:auto
  }