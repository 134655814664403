@keyframes dash {
    from {
      stroke-dashoffset: 372;
    }
  }
  .wrapper{
    margin: auto;
    width: 20vw;
    margin-bottom:20px;
    margin-top: 10px;
    height: 30vh;
    border-radius: "10px";
    overflow-y: scroll;
  }
.circular-progress-outer {
    width:150px;
    margin: auto;
    height: 150px;
    background-color: #f5f7f3;
    border-radius: 50%;
    padding: 20px;
    border: 4px solid #f3f4f7;
    box-shadow: 7px 7px 15px rgba(55, 84, 170, .15),
      -7px -7px 20px rgba(255, 255, 255, 1),
      inset 0px 0px 4px rgba(255, 255, 255, 0),
      inset 7px 7px 15px rgba(55, 84, 170, .15),
      inset -7px -7px 20px rgba(255, 255, 255, 1),
      0px 0px 4px rgba(255, 255, 255, .2) !important;
}

.circular-progress-inner {
    width:150px;
    height: 150px;
    border-radius: 50%;
    position: relative;
    background-color: #f3f4f7;
    justify-content: center;
    box-shadow: 7px 7px 15px rgba(55, 84, 170, .15),
      -7px -7px 20px rgba(255, 255, 255, 1),
      0px 0px 4px rgba(255, 255, 255, .2) !important;
}

.circular-progress-circle{
    fill: none;
    stroke: crimson;
    stroke-width: 10px;
    stroke-dasharray: 528;
    animation: dash 1s linear forwards;
    transform: rotate(-90deg);
    transform-origin: center center;
}
.circular-progress-bar{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.circular-progress{
    position: relative;
}
.header-wrapper{
  display: flex;
  width: 100%;
}
.header-title{
  color: #185BF1;
  font-weight: bolder;
  font-size: 1.5vw;
  margin-left: 10px;
}
.header-logo{
  width:1.3vw;
  height:2.5vh;
  margin-left: 5px;
}
.status{
  display: flex;
  margin-left: 10px;
  color: #a3aab9;
  font-weight: bolder;
}
.status-active{
  color: #008000;
}
.status-inactive{
  color: #FF0000;
}