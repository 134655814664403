.wrapper{
    margin: auto;
    width: 95%;
    margin-bottom: 20px;
    margin-top: 20px;
    height: 25vh;
    border-radius: 10px;
    overflow-y: scroll;
}
.subscription{
    overflow-y: scroll;
    width: 90%;
    height: 16vh;
    border-radius: 10px;
    margin: auto;
    border: none;
}
.subscription-wrapper{
    display: flex;
    margin-top: 10px;
    font-weight: 700;
    font-size: 20px;
}
.subscription-item-wrapper{
    display: flex;
    margin-right: 20px;
    min-width: 200px;
}
.subscription-item-id{
    margin-right: 10px;
    color: black;
    width: 20px;
}
.subscription-item-value{
    color: #324e63;
}
.subscription-item-key{
    color: #a3aab9;
}
.summary-key-text{
    font-weight: bolder;
    color: #a3aab9;
    font-size:1.2vw;
  }

.summary-value-text{
    font-weight: bolder;
    color: #0740bb;
    font-size:1.2vw;
    margin-left: 5px;
  }