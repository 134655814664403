:root {
  --value: 40%;
  --back-color: #f2f3f7;
  --primary-light: #8abdff;
  --primary: #6d5dfc;
  --primary-dark: #5b0eeb;
  --white: #FFFFFF;
  --greyLight-1: #E4EBF5;
  --greyLight-2: #c8d0e7;
  --greyLight-3: #bec8e4;
  --greyDark: #9baacf;
}
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::placeholder {
  text-align: center; 
}
/* or, for legacy browsers */

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
  text-align: center;  
}


::-moz-placeholder {  /* Firefox 19+ */
  text-align: center;  
}

:-ms-input-placeholder {  
  text-align: center; 
}

body {
  background-color: var(--back-color);
  text-align: center;
  justify-content: center;
}

.btn:focus {
  outline: none !important;
}

.neumorphic-btn {
  font-size: 20px;
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 10px;
  color: #6D7587;
  background-color: var(--back-color);
  box-shadow: 8px 8px 12px -2px rgba(172, 173, 175, 0.4), -6px -6px 12px -1px white;
  transition: all 250ms ease-in-out;
  cursor:pointer;
}
.neumorphic-btn:hover {
  box-shadow: none;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  background-color: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
  margin-top: 40px;
  margin-bottom: 40px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:#6D7587;
  border-radius: 10px;
}
.neumorphic-btn:active {
  box-shadow: inset -4px -4px 6px -1px white, inset 2px 2px 8px -1px rgba(72, 79, 96, 0.5);
  border-color: rgba(255, 255, 255, 0.14);
}

.neumorphic-progress,
.neumorphic-slider {
  width: 35vw;
  margin: auto;
  padding-bottom: 5vh;
  margin-right: 1vw;
}

.neumorphic-progress__back,
.neumorphic-slider__back {
  height: 15px;
  background-color: var(--back-color);
  border-radius: 10px;
  border: 4px solid #f3f4f7;
  box-shadow: 7px 7px 15px rgba(55, 84, 170, .15),
    -7px -7px 20px rgba(255, 255, 255, 1),
    inset 0px 0px 4px rgba(255, 255, 255, 0),
    inset 7px 7px 15px rgba(55, 84, 170, .15),
    inset -7px -7px 20px rgba(255, 255, 255, 1),
    0px 0px 4px rgba(255, 255, 255, .2) !important;
}

.neumorphic-slider {
  width: 240px;
}

.neumorphic-slider__back {
  width: calc(100% + 20px);
  margin-left: -10px;
}

.neumorphic-progress__line {
  height: 5px;
  margin-top: 10px;
  border-radius: 8px;
  opacity: 1;
  transition: all .75s ease;
}

@keyframes sliding {
  0% {
    width: 0;
  }

  100% {
    width: calc(100% - 8px);
  }
}

.neumorphic-slider {
  position: relative;
}

.neumorphic-slider__line {
  height: 16px;
  background-color: #185BF1;
  margin-top: -20px;
  margin-left: -6px;
  border-radius: 8px;
  opacity: 1;
  width: calc(var(--value) + 4px);
}


.neumorphic-slider__popover {
  position: absolute;
  background-color: var(--back-color);
  left: var(--value);
  width: 60px;
  height: 40px;
  top: -60px;
  margin-left: -30px;
  box-shadow: 7px 7px 15px rgba(55, 84, 170, .15),
    -7px -7px 20px rgba(255, 255, 255, 1),
    0px 0px 4px rgba(255, 255, 255, .2);
  color: #6D7587;
  padding: 8px 0;
}

.neumorphic-slider__popover::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  bottom: -6px;
  left: 50%;
  margin-left: -6px;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: var(--back-color) transparent transparent transparent;
}

.neumorphic-text {
  color: #a3aab9;
  margin: auto 30px;
  font-size: 100px;
}

.neumorphic-checkbox {
  padding: 20px;
  position: relative;
  margin: 0 5px;
  transition: all .25s ease;
}

.neumorphic-checkbox::after {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #a3aab9;
  background: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all .25s ease;
}

.neumorphic-checkbox_active {
  box-shadow: 7px 7px 15px rgba(55, 84, 170, .15),
    -7px -7px 20px rgba(255, 255, 255, 1),
    inset 0px 0px 4px rgba(255, 255, 255, 0),
    inset 7px 7px 15px rgba(55, 84, 170, .15),
    inset -7px -7px 20px rgba(255, 255, 255, 1),
    0px 0px 4px rgba(255, 255, 255, .2) !important;
}

.neumorphic-checkbox_active::after {
  background-color: #a3aab9;
}

.neumorphic-card {

  background-color: var(--back-color);
  box-shadow: 7px 7px 15px rgba(55, 84, 170, .15),
    -7px -7px 20px rgba(255, 255, 255, 1),
    0px 0px 4px rgba(255, 255, 255, .2) !important;
  border-radius: 30px;
  overflow-x: hidden; 
  overflow-y: scroll;
}
.neumorphic-card-list {
  box-shadow: 7px 7px 15px rgba(55, 84, 170, .15),
    -7px -7px 20px rgba(255, 255, 255, 1),
    0px 0px 4px rgba(255, 255, 255, .2) !important;
  border-radius: 30px;
}
::-webkit-scrollbar-thumb:horizontal:active,
.neumorphic-card__title {
  color: #555F76;
  font-size: 22px;
  padding: 20px 0 10px;
  text-align: start;
}

.neumorphic-card__text {
  padding: 0 0 10px;
  text-align: start;
  color: #8D96A8;
}

.neumorphic-card__outer {
  background-color: #f3f4f7;
  border-radius: 10px;
  border: 5px solid #f3f4f7;
  box-shadow: 7px 7px 15px rgba(55, 84, 170, .15),
    -7px -7px 20px rgba(255, 255, 255, 1),
    0px 0px 4px rgba(255, 255, 255, .2) !important;
  margin: -5px;
}

.neumorphic-label {
  color: #868b98;
  display: block;
  text-align: start;
  margin: 25px 10px 5px;
}

.neumorphic-input {
  background-color: var(--back-color);
  border-radius: 10px;
  border: 3px solid #f3f4f7;
  box-shadow: 2px 2px 3px rgba(55, 84, 170, .15),
    inset 0px 0px 4px rgba(255, 255, 255, 0),
    inset 7px 7px 15px rgba(55, 84, 170, .15),
    inset -7px -7px 20px rgba(255, 255, 255, 1),
    0px 0px 4px rgba(255, 255, 255, .2) !important;
  padding: 5px 15px;
  outline: none !important;
  color: #535D74;
  font-size: 1.8vw;
  font-weight: bolder;
  color: #881fff;
}

.neumorphic-image {
  border-radius: 10px;
}

.card-collection .neumorphic-card {
  display: inline-block;
  margin: 0 10px !important;
}

.neumorphic-outer {
  box-shadow: 7px 7px 15px rgba(55, 84, 170, .15),
    -7px -7px 20px rgba(255, 255, 255, 1),
    0px 0px 4px rgba(255, 255, 255, .2) !important;
  background-color: var(--back-color);
  border-radius: 30px;
}

.neumorphic-inner {
  box-shadow: inset 0px 0px 4px rgba(255, 255, 255, 0),
    inset 7px 7px 15px rgba(55, 84, 170, .15),
    inset -7px -7px 20px rgba(255, 255, 255, 1),
    0px 0px 4px rgba(255, 255, 255, .2) !important;
  border-radius: 10px;
}

.neumorphic-tab-container {
  display: flex;
  flex-direction: column;
}

.neumorphic-tab-container__controls {
  display: flex;
  border: 4px solid var(--back-color);
  width: min-content;
  background-color: var(--back-color);
  border-radius: 15px;
  margin-bottom: 15px;
}

.neumorphic-tab-container__control {
  padding: 10px 10px;
  color: #535D74;
  background-color: var(--back-color);
  box-shadow: inset 0px 0px 4px rgba(255, 255, 255, .2), inset 7px 7px 15px rgba(55, 84, 170, 0), inset -7px -7px 20px rgba(255, 255, 255, 0);
  transition: box-shadow .4s ease;
  cursor: pointer;
}

.neumorphic-tab-container__control:first-child {
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}

.neumorphic-tab-container__control:last-child {
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.neumorphic-tab-container__control_active {
  box-shadow: inset 0px 0px 4px rgba(255, 255, 255, 0), inset 7px 7px 15px rgba(55, 84, 170, .15), inset -7px -7px 20px rgba(255, 255, 255, 1);
}

.neumorphic-tab-container__tab {
  display: none;
  padding: 20px;
}

.neumorphic-tab-container__tab_shown {
  display: flex;
}