@keyframes dash {
    from {
      stroke-dashoffset: 372;
    }
  }
.result-wrapper{
    margin: auto;
    width: 95%;
    margin-bottom: 40px;
    margin-top: 20px;
    height: 30vh;
    border-radius: 10px;
    overflow-y: scroll;
}

.result-detail-row{
    display: flex;
    margin-top: 5px;
}

.result-detail{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;

}
.result-detail-key{
    color: #324e63;
    font-weight: 600;
}
.result-detail-value{
    color: rgb(3, 89, 42);
}
.result-detail-value-na{
    color: red;
}
.detail-btn{
    font-family: inherit;
    font-weight: 600;
    height: 40px;
    font-size: 15px;
    width: 80px;
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 30px;
    color: #6D7587;
    background-color: #f2f3f7;
    box-shadow: 8px 8px 12px -2px rgba(172, 173, 175, 0.4), -6px -6px 12px -1px white;
    transition: all 250ms ease-in-out;
    cursor:pointer;
    color: #6944ff;
  }
  .detail-btn:active {
    box-shadow: inset -4px -4px 6px -1px white, inset 2px 2px 8px -1px rgba(72, 79, 96, 0.5);
    border-color: rgba(255, 255, 255, 0.14);
  }

  .summary-key-text{
    font-weight: bolder;
    color: #a3aab9;
    font-size:1.2vw;
  }

  .summary-value-text{
    font-weight: bolder;
    color: #0740bb;
    font-size:1.2vw;
    margin-left: 5px;
  }
  .summary-wrapper{
    display: flex;
    justify-content: center;
  }
  .summary-column{
    display: flex;
    margin-left: 40px;
    margin-top: 10px;
  }
  .status{
    font-weight: bolder;
}
  .status-success{
    color: #09a706;
  }
  .status-running{
    color: #f89b05;
}
  .status-failed{
    color: #f80505;
}
.circular-progress-outer {
    width:100px;
    height: 100px;
    background-color: #f5f7f3;
    border-radius: 50%;
    padding: 20px;
    border: 4px solid #f3f4f7;
    box-shadow: 7px 7px 15px rgba(55, 84, 170, .15),
      -7px -7px 20px rgba(255, 255, 255, 1),
      inset 0px 0px 4px rgba(255, 255, 255, 0),
      inset 7px 7px 15px rgba(55, 84, 170, .15),
      inset -7px -7px 20px rgba(255, 255, 255, 1),
      0px 0px 4px rgba(255, 255, 255, .2) !important;
}

.circular-progress-inner {
    width:100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    background-color: #f3f4f7;
    justify-content: center;
    box-shadow: 7px 7px 15px rgba(55, 84, 170, .15),
      -7px -7px 20px rgba(255, 255, 255, 1),
      0px 0px 4px rgba(255, 255, 255, .2) !important;
}

.circular-progress-circle{
    fill: none;
    stroke-width: 10px;
    stroke-dasharray: 375;
    animation: dash 1s linear forwards;
    transform-origin: center center;
    transform: rotate(-90deg);
    stroke-linecap:round;
}
.circular-progress-bar{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.circular-progress{
    position: relative;
}
.result-table-wrapper{
    display: flex;
    justify-content: flex-start;
    margin-top: 30px;
    margin-left: 70px;
}
.result-table{
    width: 70%;
    height: 18vh;
    border-radius: 10px;
    margin-right: 10px;
    margin-left: 12vw;
    border: solid 0.5px;
    overflow-y: scroll;
}

.cicular-text{
    color: #324e63;
    font-weight: 600;
}