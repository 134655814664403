.loggedOut{
    height: 300px;
    transition: all .3s ease-out;
}
.loggedIn{
    top: 100%;
    height: 90px;
    transition: all .3s ease-out;
    z-index: 1;
}
