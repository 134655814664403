@-webkit-keyframes wobbleSpin {
    0% {
      transform: rotate(0);
    }
    16% {
      transform: rotate(168deg);
    }
    37% {
      transform: rotate(68deg);
    }
    72% {
      transform: rotate(384deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes wobbleSpin {
    0% {
      transform: rotate(0);
    }
    16% {
      transform: rotate(168deg);
    }
    37% {
      transform: rotate(68deg);
    }
    72% {
      transform: rotate(384deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes pulse {
    0%,100% {
      box-shadow: 0 0 0 0 #fa8615;
    }
    50% {
      box-shadow: 0 0 10px 15px #fa8615;
    }
  }
  @keyframes pulse {
    0%,100% {
      box-shadow: 0 0 0 0 #fa8615;
    }
    50% {
      box-shadow: 0 0 10px 15px #fa8615;
    }
  }
  @-webkit-keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  
  .wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  .loading {
    position: relative;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background: url("http://fairwheelbikes.com/includes/templates/fwb/images/logo.png") no-repeat;
    margin: 20px, 10px;
    font-size: 12px;
    font-weight: bolder;
    color: #d41f9c;
    cursor: pointer;
    z-index: 0;
  }

  .loading:after {
    content: " ";
    position: absolute;
    margin-top: 22px;
    height: 60px;
    width: 60px;
    border-radius: 70%;
    top: -15px;
    left: -8px;
    box-shadow: 7px 7px 15px rgba(55, 84, 170, .15),
    -7px -7px 20px rgba(255, 255, 255, 1),
    inset 0px 0px 4px rgba(255, 255, 255, .2),
    inset 7px 7px 15px rgba(55, 84, 170, 0),
    inset -7px -7px 20px rgba(255, 255, 255, 0),
    0px 0px 4px rgba(255, 255, 255, 0) !important;
  }

  .running:after {
    border: 6px solid transparent;
    border-top-color: #881fff;
    border-bottom-color: #881fff;
    -webkit-animation: wobbleSpin 2s ease-in-out infinite;
    animation: wobbleSpin 2s ease-in-out infinite;
    transition: all .25s ease;
  }
  .failed:after {
    border: 4px solid transparent;
    /* border-color: #fa1515; */
    transition: all .25s ease;
  }
  .success:after {
    border: 4px solid transparent;
    /* border-color: #07f332; */
    transition: all .25s ease;
  }
  .partial:after {
    border: 4px solid transparent;
    transition: all .25s ease;
    /* border-color: #efa30c; */
  }