:root {
  --highlight: #fa0606;
}

.navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.list-item {
  list-style-type: none;
  flex-basis: 100%;
  min-height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;
  transform: translateY(0);
  transition: transform 0.5s ease, opacity 0.2s ease;
  cursor: pointer;
}

.list-item-name {
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  transform: translate(0, 50px);
  transition: transform 0.5s ease, opacity 0.2s ease;
  opacity: 0;
}

.list-item:hover {
  color: var(--highlight);
  transform: translateY(-6px);
}
.list-item:hover .list-item-name {
  transform: translateY(20px);
  opacity: 1;
}

@media (max-width: 350px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    max-width: 120px;
    padding-bottom: 20px;
  }

  .list-item {
    flex-basis: auto;
  }

  .list-item:hover .list-item-name {
    transform: translateY(25px);
  }
}